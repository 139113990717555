import * as Vue from 'vue'
import Layout from '@/layout'

export default {
  path: '/files',
  component: Layout,
  name: 'files', //文档资料
  alwaysShow: true,
  meta: {title: 'menus.file', icon: 'books'},
  children: [
    {
      path: 'index',
      name: 'technical_files_list',
      component: Vue.defineAsyncComponent(
        () => import('@/views/files/index.vue')
      ),
      meta: {title: 'menus.technical_files', icon: 'tech'},
    },
    {
      path: 'PN_files_details/:id(\\d+)',
      name: 'PN_files_details',
      component: Vue.defineAsyncComponent(
        () => import('@/views/files/PN_files_details.vue')
      ),
      props: true,
      hidden: true,
      meta: {title: 'menus.technical_files', icon: 'tech'},
    },
    {
      path: 'files_classification',
      name: 'files_classification',
      component: Vue.defineAsyncComponent(
        () => import('@/views/files/file_classification.vue')
      ),
      meta: {title: 'menus.file_classification', icon: 'tech'},
    },
  ],
}
