export const zh = {
  general: {
    remark: '备注',
    message: '预判备注',
    status: '状态',
    actions: '操作',
    search: '搜索',
    quotations: '报价单',
    add: '添加',
    print: '打印',
    batch_add: '批量添加',
    add_prognosis: '预判',
    view: '查看',
    edit: '编辑',
    save: '保存',
    cancel_1: '取消',
    approve: '保存',
    delete: '删除',
    remove: '移除',
    deleteall: '批量删除',
    duplicate: '复制',
    logout: '退出登录',
    confirm: '确 定',
    cancel: '取 消',
    discard: '放弃',
    yes: '是',
    no: '否',
    ok: '好的',
    prompt: '提示',
    warning: '提示',
    profile: '用户信息',
    passwd: '修改密码',
    quantity: '数量',
    permission: '您当前无权限进行该操作',
    done: '去完成',
    add_field: '添加属性',
    download: '导出数据',
    export: '下载文件',
    upload: '上传文件',
    model: '产品型号',
    time: '时间',
    copy: '复制',
    advanced: '高级查询',
    advanced_filter: '高级查询',
    reset: '重置',
    aps: '排产',
    content: '内容',
    dropdown: '下拉',
    keyword: '关键字',
    note: 'Note',
    editCompany: '编辑公司信息',
    editContacts: '编辑联系人信息',
    total: '总计',
    lang: '语言',
    downloading: '正在下载,请稍后...',
    uploading: '正在上传,请稍后...',
    down: '下载成功',
    download_failed: '下载失败,请重试',
    reset_pws: '重置密码',
    domestic: '国内',
    foreign: '国外',
    del: '删除成功',
    cancel_del: '已取消删除',
    completed: '已完成',
    completed_time: '完成时间',
    please_select: '请选择...',
    add_new_label_dialog: '添加新产品品名',
    lading: '加载中...',
    delete_prompt: '此操作将永久删除该信息, 是否继续?',
    deleted_success: '删除成功',
    deleted_canceled: '已取消删除',
    feedback_read: '已反馈',
    feedback_unread: '未反馈',
    basic_info: '基本信息',
    details_info: '详细信息',
    back: '返回',
    examine_and_approve: '审批',
    last_update_time: '更新时间',
    last_update_user: '更新人',
  },
  name: '名称',
  PN: '订货号',
  intro: '说明',
  category: '种类',
  family: '系列',
  classfication: '产品种类',
  description: '产品描述',
  item_type: '产品类型',
  region: '销售区域',
  label_name: '品名',
  status: 'PN状态',
  US_description: '美国产品描述',
  US_name: '美国名称',
  dwg: '图纸',
  range: '范围',
  unit: '单位',
  Accuracy: '精度',
  measure_name: '测量名称',
  LOD: '最低检出限',
  '4-20mA output': '4-20mA输出',
  'Wiring Diagram': '接线图',
  Modbus: 'Modbus',
  'storage temperature': '存储温度',
  'Working Temperature': '环境温度',
  'Minimum Flow': '最低流量',
  'Maximum Flow': '最高流量',
  'Maximum Pressure': '最大承压',
  Size: '外形尺寸',
  Weight: '重量',
  'Housing Material': '壳体材料',
  'Electrode Material': '电极材料',
  'Field Installation': '安装方式',
  'Connector Type': '线缆类型',
  'Wiring Definition': '线缆定义',
  'IP Rating': '防护等级',
  'Hardware Version': '硬件版本',
  'Software Version': '软件版本',
  'Latest Software': '最新版本软件',
  'Select the image': '选择图片',
  'Select the file': '选择文件',
  'Upload the certification': '上传认证',
  'Upload pictures': '上传图片',
  'Add the attachment': '增加配件',
  'check file': '查看文件',
  'check attachment': '查看附件',
  file_: '文件资料',
  'Measure principle': '测量原理',
  'Light Source': '光源信息',
  Repeatability: '重现性',
  'Sample Interval': '采样间隔',
  'Response Speed': '响应速度',
  'Interface Size': '接口尺寸',
  'Environment Humidity': '环境湿度',
  Altitude: '海拔高度',
  'Sample Water Temperature': '水样温度',
  'Minimum Flow Rate': '水样流速',
  'Working Pressure': '工作压力',
  '2D/3D File': '2D/3D文件',
  'Thread Standard': '螺纹标准',
  'Installation Methods': '安装方式',
  'Warranty Time': '质保期',
  'Maintenance Intervals': '维护周期',
  'Calibration Period': '校准周期',
  'Cleaning Kit': '清洗套装',
  'Maintenance Mode': '维护方式',
  'Cable Length': '线缆长度',
  'Environment Temperature': '环境温度',
  'BOM Version': 'BOM版本',
  'Drop file here or': '拖动文件到此处或',
  'click to upload': '点击上传',
  accessories: '配件',
  accuracy: '测量精度',
  analog: '模拟输出',
  certificates: '产品认证',
  detection: '检测下限',
  flow: '流动限制',
  group: '分类',
  group_placholder: '请选择分类',
  hardware: '硬件版本',
  install: '安装格式',
  ip_rating: '防护等级',
  last_software: '最新软件',
  material: '壳体材料',
  modbus: '通讯输出',
  output_pin: '输出引线格式',
  power: '电源要求',
  pressure: '压力范围',
  rang: '测量范围',
  resolution: '测量分辨率',
  software: '软件版本',
  temperature: '工作温度范围',
  mechnical_draw: '机械图纸',
  image: '产品图像',
  fluorescence: '荧光',
  conductivity: '电导率',
  turbidity: '浊度',
  do: '溶解氧',
  disinfection: '消毒',
  environment: '环境',
  output: '输出',
  basic_information: '基本信息',
  measure_index: '测量指标',
  input_output: '输入输出',
  environmental_requirement: '环境要求',
  job_requirement: '工作要求',
  size_weight: '尺寸重量',
  materials_equipment: '设备材料',
  install_information: '安装信息',
  after_sales_maintenance: '售后维护',
  software_hardware_version: '软硬件版本',
  product_certification: '产品认证',
  product_image: '产品图片',
  product_accessories: '产品配件',
  product_information: '产品资料',
  choose_template: '选择模板',
  model: {
    name: '名称',
    PN: '订货号',
    intro: '描述',
    retail_price: '零售价格',
    t1_price: 'T1价格',
    t2_price: 'T2价格',
    t3_price: 'T3价格',
    t4_price: 'T4价格',
    currency: '货币',
    num: '数量',
    quantity: '数量',
    total_price: '总价',
    cny: '人民币',
    usd: '美元',
    accessories: '配件',
    accuracy: '测量精度',
    analog: '模拟输出',
    certificates: '产品认证',
    detection: '检测下限',
    flow: '流动限制',
    group: '分类',
    hardware: '硬件版本',
    install: '安装格式',
    ip_rating: '防护等级',
    last_software: '最新软件',
    material: '外壳材料',
    modbus: '通讯输出',
    output_pin: '输出引线格式',
    power: '电源要求',
    pressure: '压力范围',
    rang: '测量范围',
    resolution: '测量分辨率',
    software: '软件版本',
    temperature: '工作温度范围',
    mechnical_draw: '机械图纸',
    image: '产品图像',
    fluorescence: '荧光',
    conductivity: '电导率',
    turbidity: '浊度',
    do: '溶解氧',
    environment: '环境',
    cooling_water: '工业冷却循环水',
    boiler: '锅炉',
    drinking_water: '自来水',
    municipal_wastewater: '市政污水',
    industrial_wastewater: '工业废水',
    food_beverage: '食品饮料行业',
    pharmaceutical: '制药行业',
    disinfection: '杀菌消毒',
    add_accessories: '添加配件',
    accessories_info: '配件基本信息',
    make: '制造',
    buy: '采购',
    oem: 'OEM',
    pending: '待审批',
    us_name: 'US 名称',
    us_intro: 'US 描述',
    log: '日志',
    no_match: '无相关型号',
    inactive: '申请失败',
    active: '申请通过',
    draft: '申请中',
    cabledialogtitle: '请选择线材类型',
    new_label_tip: '请输入分类标识',
    new_label_remark_tip: '请输入分类标识',
    approval_pending: "待审批",
    approval: "审批",
    brand: '品牌',
    brand_name: '品牌名称',
    brand_intro: '品牌描述',
    new_brand_title: "添加品牌",
    edit_brand_title: "编辑品牌",
    add_brand_success: "添品牌成功",
    add_brand_error: "添加品牌失败",
    edit_brand_error: "编辑品牌失败",
    edit_brand_success: "编辑品牌成功",
    service: '服务'
  },
  product: {
    SN: '产品序列号(SN)',
    tester: '检测员',
    date_produced: '生产日期',
    status: '状态',
    add_product: '添加产品',
  },
  RMA: {
    serviceReport: '维修报告',
    number: '售后编号',
    time: '退货时间',
    customer: '客户名称',
    user: '检测人',
    is_expired: '是否过保',
    type: '服务类型',
    feedback: '客户反馈',
    conclusion: '故障分析',
    mode: '处理方式',
    status: '服务状态',
    time_complated: '完成时间',
    progress: '服务进度',
    step: '进度',
    // new
    Accountname: '帐户名称',
    priceprogram: '价格计划',
    terms: '费用',
    accountid: '帐户ID',
    fax: '传真',
    date: '设置日期',
    contactperson: '主要联系人名',
    lastperson: '主要联系人姓氏',
    contactnumber: '主要电话',
    contactemail: '主电子邮件',
    accountidassociated: '关联的帐户ID',
    billtoemail: '账单邮件',
    billone: '帐单寄送至1',
    billtwo: '帐单寄送至2',
    billthree: '帐单寄送至3',
    billfour: '帐单寄送至4',
    billfive: '帐单寄送至5',
    shipone: '收货方1',
    shiptwo: '收货方2',
    shipthree: '收货方3',
    shifour: '收货方4',
    shifive: '收货方5',
    buyersname: `买方名称`,
    upsaccount: 'UPS帐户',
    address: '客户地址',
    postal: '客户邮政',
    note: '笔记',
    status: '状态',

    rma: '售后号',
    applicationDate: '报修日期',
    caseStatus: '案例状态',
    personnelTaking: '售后接收人',
    nameNeeding: '终端用户姓名',
    companyNeeding: '终端用户公司名称',
    typeofProduct: '产品名称',
    productSerialNumber: 'PN/SN',
    reportedNeed: '用户反馈描述',
    resolution: '解决措施',
    failureMode: '故障类型',
    diagnosis: '诊断情况/原因诊断',
    SNProducts: '换货PN/SN',
    nameofApplicant: '报修人',
    companyofApplicant: '报修人单位',
    returnShippingAddress: '退货地址',
    emailofIndividualNeeding: '终端用户个人邮箱',
    phoneNumberofIndividualNeeding: '终端用户联系方式',
    phoneNumberofIndividualNeedingOld: '终端用户联系方式2',
    previousRMAAssociated: '以前的RMA关联',
    PrintAsAList: '以列表形式打印',
    PrintAsSummary: '以摘要形式打印',
    resend: '发送邮件',
    copy: '邮件内容',
    warn: '邮件内容为空',
  },
  // new
  aRMA: {
    upimage: '点击上传',
    newservicerequest: '新服务请求',
    introduction:
      '请正确填写此表格它将作为信息的副本通过电子邮件发送至您点击“提交”按钮时的客户。',
    nameofapplicant: '报修人',
    companyofapplicant: '报修人单位',
    emailofapplicant: '报修人邮箱',
    phoneofapplication: '二次报修人联系方式',
    phoneofapplicationold: '首次报修人联系方式',
    applicationdate: '报修日期',
    returnshippingaddress: '退货地址',
    endUsertheSameasApplicant: '终端用户是否和报修人一致？',
    nameofIndividualNeedingService: '终端用户姓名',
    companyofIndividualNeedingService: '终端用户公司名称',
    emailofIndividualNeedingService: '终端用户个人邮箱',
    phoneNumberofIndividualNeedingService: '终端用户联系方式',
    phoneNumberofIndividualNeedingServiceOld: '终端用户联系方式2',
    pyxisPersonnelTakingRequest: '售后接收人',
    oldPyxisPersonnelTakingRequest: '以前的Pyxis人员接受请求',
    previousRMAassociates: '以前的RMA关联',
    typeofproduct: '产品名称',
    productSerialNumber: 'PN/SN',
    reportedServiceNeed: '用户反馈描述',
    picturesofproblem: '问题图片',
    RMA: '售后号',
    returnTracking: '退货快递单号',
    dateDefectiveMeterReceived: '送修日期',
    failureMode: '故障类型',
    diagnosis: '诊断情况/原因诊断',
    replacementProductShipped: '换货日期',
    SNofReplacedProducts: '换货PN/SN',
    resolution: '解决措施',
    pyxisPersonnelOfferingResolution: '维修负责人',
    serviceCost: '售后服务费用',
    casestatus: '案例状态',
    confirmprinting: '确认打印?',
    export_pdf: '导出PDF',
  },
  failureMode_status: {
    batteriesCorroded: '电池腐蚀',
    Buttonfailure: '按钮故障',
    crackedcasing: '外壳破裂',
    Customerservice: '客户服务',
    electronicfailureout: '电子故障（过保）',
    electronicfailurereplaceunit: '电子故障（更换模块）',
    hardwareerror: '硬件错误',
    manufacturingdefect: '制造缺陷',
    nofaultfoundorcleaning: '未发现故障/清洁',
    notenoughinformation: '信息不足',
    phbattery: 'PH模块电池',
    phmodulefailure: 'ph模块故障',
    samplecellleak: '样品池泄漏',
    SDcarderror: 'SD卡故障',
    softwarefailure: '软件故障',
    unstablereading: '读数不稳定',
    waterDamage: '漏水',
    other: '其它',
  },
  resolution_status: {
    repairedandreturned: '已修复并送回',
    newreplacement: '换新',
    usedreplacement: '发备用机',
    noproblemandreturned: '没问题并送回',
    outofwarranty: '超出保修期',
    other: '其它',
  },
  case_status: {
    new: '新开',
    revieworRepairInProgress: '审查/维修中',
    TESTINGWaitingForPayment: '测试：等待付款',
    closed: '关闭',
    received: '已收到',
  },
  // new contacts
  contacts: {
    name: '客户名称',
    company: '账户',
    enduseraccount: '最终用户帐户',
    role: '角色',
    businessname: '企业名称',
    addressone: '地址1',
    addresstwo: '地址2',
    addressthree: '地址3',
    city: '城市',
    state: '状态',
    zipcode: '邮政编码',
    country: '国家',
    receivingaddress: '收货地址',
    email: '邮箱',
    directphone: '第一联系方式',
    secondphone: '第二联系方式',
    upsaccount: '付款方式',
    specialnotes: '注意事项',
    addtime: '添加时间',
  },
  role_status: {
    user: '用户',
    buyer: '采购方',
    tech: '技术',
    distributor: '经销商',
    other: '其它',
  },
  customer_status: {
    active: '激活',
    inactive: '未激活',
    reactive: '重新激活',
    nameChanged: '名称已更改',
    other: '其它',
  },
  price: {
    listprice: '标价',
    awt: 'AWT',
    smallDistribution: '小型分销',
    bulkDistribution: '大型分销',
    asiaPacific: '亚太地区',
    other: '其它',
  },
  terms: {
    Net30: '净额30',
    Net60: '净额60',
    advance: '100%预付款',
    dueOnReceipt: '收到付款',
    creditCard: '信用卡',
    paymentInAdvance: '预付款',
    other: '其它',
  },

  RMA_status: {
    all: '全部',
    done: '已完成',
    undone: '未完成',
    cancel: '已取消'
  },
  regions: {
    all: '所有',
    us: '美国',
    cn: '中国',
    eu: '欧洲',
    other: '其他',
  },
  RMA_type: {
    return: '退换货',
    back: '外借退回'
  },
  RMALog: {
    status: '状态',
    time: '时间',
    user: '用户',
    userInfo: '用户'
  },
  WL: {
    number: '快递单号',
    express: '快递公司',
    from: '发件信息',
    to: '收件信息',
    addr: '发件地址',
    time: '发件时间',
    shipper: '发件人',
    consignee: '收件人',
    address: '收件地址',
    received_date: '签收时间',
  },
  Dep: {
    name: '部门名称',
    intro: '部门介绍',
    date_joined: '添加时间',
    last_update: '更新时间',
  },
  user: {
    address: '地址',
    date_joined: '添加时间',
    email: '邮箱地址',
    is_active: '是否激活',
    is_admin: '管理员',
    name: '姓名',
    phone: '手机号码',
    type: '类型',
    username: '用户名',
    password: '密码',
    nomal: '普通用户',
    dep_admin: '部门管理员',
    admin: '超级管理员',
    manager: '管理员',
    edit_role: '编辑角色',
  },
  other: {
    to: '至',
    start: '开始日期',
    end: '结束日期',
    search: '请输入搜索',
    sn_search: '请输入SN搜索',
    progress: '更新售后进度',
    order_pro: '发货数量',
    error: '操作失败，请重试',
    success: '操作成功',
    confirm: '您是否确定删除该条数据',
    select_datetime: '选择日期时间',
    select_date: '选择日期',
    select_time: '选择时间',
    select: '请选择',
    two: '请正确输入数据,其中PN为五位数,SN为六位数，中间用符号‘/’分割',
    order_hint: '*本次发货的数量，与此订单已经发货的数量无关',
    prompt_info: '是否要保存当前页面数据并进入下一页/上一页',
    save_info: '是否要保存当前页面数据',
    input: '请输入内容',
    quick_answer: '预判状态',
    approve_confirm: '申请成功',
    required: '此字段是必填字段。请输入一个值。',
    email: '是否发送邮件？',
    reset: '重置密码',
    enter_num: '请输入数字',
    select_region: '请选择销售区域',
    select_shipping: '请选择发货状态',
    del_order: '此操作将永久删除该订单, 是否继续?',
    del_confirm: '是否删除',
    action_warn: '是否继续次操作?',
  },
  prog: {
    receive: '退货受理',
    totest: '收到货，移交检测',
    fault: '故障分析',
    mode: '处理方式',
    conclusion: '结果判定',
  },
  basicInfo: {
    RMA: '基本信息',
    Log: '日志信息',
    WL: '物流信息',
    User: '用户信息',
    Group: '部门信息',
    Product: '产品基本信息',
    Model: '产品型号基本信息',
  },
  prognosis: {
    OK: '可排产',
    lack_materials: '缺料',
    technical_difficulties: '存在技术难题',
    in_stock: '库存满足',
    in_stock1: '库存部分满足',
    tight_delivey_date: '交期紧张',
    prognosis_status: '预判状态',
    stock_allot: '库存初分配',
    choose: '请选择',
  },
  bug: {
    time: '测试时间',
    user: '测试人',
    version: '测试版本',
    task: '测试任务',
    result: '测试结果',
    issue: '问题描述',
    attachment: '附件',
  },
  CRP: {
    time: '报缺时间',
    name: '物料名称',
    type: '物料类型',
    name_col: '物料名称所在列',
    code: '物料编码',
    code_col: '物料编码所在列',
    adduser: '报缺人',
    existing: '现有库存',
    existing_col: '现有库存所在列',
    demand: '警戒库存',
    quantity: '需求数量',
    quantity_col: '需求数量所在列',
    mrp_file: '物料报缺文件',
    p_time: '采购时间',
    p_user: '采购人',
    p_status: '采购状态',
    p_quantity: '采购数量',
    EDT: '预计到货日期',
    ADT: '实际到货时间',
    ADUser: '到货确认人',
    status_1: '在途',
    status_2: '已到货',
    status_0: '未采购',
    p_expected: '排产需求日期',
    p_expected_col: '排产需求日期所在列',
    reason: '原因',
    reason_d: '采购数量与需求数量相差的原因',
    priority: '优先级',
    priority_col: '优先级所在列',
    stock: '备料',
    production: '生产',
    urgent: '加急',
    rs_item: '报缺项目',
    rs_item_col: '报缺项目所在列',
    mes_1: '报缺信息',
    mes_2: '需求信息',
    mes_3: '采购信息',
    mes_4: '到货信息',
    mes_5: '商品信息',
    meter: '仪表',
    equ: '设备',
    che: '化学',
    rd: '研发',
    other: '其他信息',
    det: '商品详情',
    file: '商品文件',
    remark: '商品备注',
    link: '购买链接',
    ADUser: '签收人',
    app_note: '审批意见',
    select_app: '审批状态',
    select_pur: '采购状态',
    to_approver: '选择审批人',
    vacancy_info: '报缺信息',
    demand_info: '需求信息',
    purchasing_info: '采购信息',
    arrival_info: '到货信息',
    aog: '到货',
    IBD: '入库',
    warehouse: '仓库管理员',
    storage: '入库数量',
    storage_time: '入库时间',
    aogquantity: '到货数量',
    partial_arr: '部分到货'


  },
  wl: {
    express: '承运公司',
    trackingnumber: '运单号',
    shipper: '发件人',
    time: '发件时间',
    addr: '发件地址',
    consignee: '收件人',
    address: '收件地址',
    received_date: '签收时间',
    status_0: '已发货',
    status_1: '已收货',
    status_2: '已删除',
    edit_info: '请选择一条您要修改的物流信息',
    wl_info: '请选择至少一条数据',
    add_recipients_tip: '请选择收件人',
  },
  passwd: {
    old: '原始密码',
    new: '新密码',
    confirm: '确认密码',
    1: '修改密码成功',
    2: '原密码错误，请重新输入',
    3: '确认密码与新密码不一致',
    4: '新密码不能与原密码相同',
    5: '密码不少于6个字符'
  },
  order: {
    num: '订单编号',
    user: '下单人',
    time: '下单时间',
    del_date: '交货日期',
    expected_date: '预计交货日期',
    client: '客户名称',
    consignee: '联系人',
    contact: '联系方式',
    address: '客户地址',
    email: '联系邮箱',
    status: '状态',
    time_completed: '完成/取消时间',
    progress: '进度',
    item: '货单',
    quantity: '订单数量',
    product: '生产数量',
    producted: '已安排生产',
    send: '去发货',
    wl: '物流',
    receive: '收货',
    send_q: '本次发货数量',
    send_q_ed: '已发货',
    not_send: '件未发货',
    order: '下单',
    aggregate: '汇总',
    generate_plan: '生成计划表单',
    all_stock: '库存已分配',
    order_cancel: '订单取消',
    product_cancel: '产品取消',
    contract: '合同',
    view_contract: '查看合同',
  },
  plan: {
    PO: '计划单',
    addtime: '添加时间',
    adduser: '添加人',
    addby: '添加人',
    creator: '创建者',
    created_by: '创建者',
    level: '等级',
    soft: '软件版本',
    hard: '硬件版本',
    other: '其他',
    complete: '完成数量',
    done: '完成进度',
    email: '邮件通知',
    device: '设备组',
    instrument: '仪表组',
    select_model: '选择产品',
    other: '其他信息',
    select: '选择',
    arrange_production: '安排生产',
    batch_quantity: '批次量',
    operation_date: '操作日期',
    set_sn: '设置序列号',
    start_sn: '起始序列号',
    batch_class: '批次类',
    batch_num: '批次数量',
    batch: '批次',
    in_batches: '分批',
    whole_batch: '整批',
    stock_redistrib: '库存再分配',
    batch_count: '已安排批次',
    overdue: '逾期提醒',
    overdue1: '已逾期',
    overdue_soon: '即将逾期',
    overdue_status: '逾期状态',
    exception_add: '异常添加',
    schedule_date: '排单日期',
    allocate: '分配库存',
  },
  product_process: {
    date_entry: '要求入库信息',
    inventory: '入库进度',
    latest_entry: '最新入库日期',
    exception: '异常情况汇报',
    picking_quantity: '领料数量',
    in_assembly: '装配中',
    once_test: '一次测试中',
    under_repair: '维修中',
    reading_back: '回读中',
    stocked: '已入库',
    complete_assembly: '完成装配',
    complete_test: '完成一次检测',
    complete_repairs: '完成维修',
    complete_reading: '完成回读',
    complete_stock: '完成入库',
    diary: '操作日记',
    picking_edit: '领料编辑',
    picking: '领料',
    batch_edit: '批量编辑',
    scrapped: '报废',
    scrapped_time: '报废时间',
    finish_time: '按期完成',
    overdue_complete: '逾期完成',
    save_warn: 'SN不能同时操作入库和报废',
    complete_scrap: '报废',
  },
  file: {
    name: '文件名',
    file: '文件',
    file_placeholder: '请选择文件',
    time: '添加时间',
    add_user: '添加人',
    type: '类型',
    prog: '进度',
    hard: '硬件变更报告',
    soft: '软件变更报告',
    choujian: '抽检报告',
    test: '测试报告',
    process: '工艺变更报告',
    certificates: '产品认证',
    mechnical_draw: '机械图纸',
    image: '产品图片',
    finished_product: '成品检验记录',
    standard: '检验标准文件',
    public: '公共技术文档',
  },
  order_prog: {
    0: '已下单',
    1: '已安排生产',
    2: '已入库',
    3: '已发货',
    4: '已完成',
    5: '待安排生产',
    6: '订单已完成',
  },
  okr: {
    goal: '个人目标',
    result: '关键结果',
    first: '第一季度',
    second: '第二季度',
    third: '第三季度',
    fourth: '第四季度',
  },
  model_category: {
    handheld_devices: '手持式设备+配件',
    inline_sensors: '在线式设备+配件',
    chemical_reagents: '化学试剂',
    display_controllers_cloud: '显示器&控制器&云服务',
    code: '标识',
    name: '类名',
    new_dialog_title: "添加新的产品分类",
    new_dialog_title_edit: "编辑产品分类",
    new_dialog_tip: "请输入新的产品分类信息",
  },
  model_family: {
    handheld_devices: '手持式设备',
    handheld_acc: '手持式设备配件',
    standard_inline_sensors: '标准在线式传感器 7芯(支持4-20mA & RS-485)',
    standard_inline_sensors_acc: '标准在线式传感器配件',
    oxidizer_sensors: '氧化剂传感器 8芯(支持4-20mA & RS-485)',
    oxidizer_sensors_acc: '氧化剂传感器配件',
    dissolved_oyxgen_sensors: '溶解氧传感器 8芯(支持4-20mA & RS-485)',
    dissolved_oyxgen_acc: '溶解氧传感器配件',
    ultra_low_turbidity_sensors: '超低浊度传感器 8芯(支持4-20mA & RS-485)',
    ultra_low_turbidity_acc: '超低浊度传感器配件',
    digital_inline_sensors: '数字式在线传感器 5芯(仅支持RS-485)',
    digital_inline_acc: '数字式在线传感器配件',
    corrosion_sensors: '腐蚀速率传感器 8芯(支持4-20mA & RS-485)',
    corrosion_acc: '腐蚀速率传感器配件',
    level_sensors: '液位传感器',
    level_sensor_acc: '液位传感器配件',
    sugar_analyzer: '糖分析仪和传感器',
    sugar_analyzer_parts: '糖分析仪备件',
    inline_chemistry_analyzers: '在线式化学分析仪',
    inline_chemistry_analyzers_parts: '在线式化学分析仪备件',
    tracing_chemicals: '示踪剂',
    calibration_standards: '校准标液',
    chemical_reagents: '化学试剂',
    display_controllers_cloud: '显示器&控制器&云服务',
  },
  quote: {
    normal: '标准报价',
    special: '专用报价',
    valid: '有效',
    invalid: '无效',
    sales: '销售',
    market: '市场',
    service: '客服',
    technology: '技术',
    save_next_step: '保存并进入下一步',
    select_product: '产品选择',
    qnum: '报价单号',
    lnum: '报价行号',
    date: '日期',
    customer_name: '客户名称',
    contacts: '联络人',
    department: '职能部门',
    operation: '业务',
    company: '接单公司',
    theme: '主题',
    area: '地区',
    status: '状况',
    terms: '选择条款',
    text: '备注',
    title: '选择产品',
    china: '中国',
    usa: '美国',
    europe: '欧洲',
    quotation_details: '报价单详情',
    file_format: '文件格式',
    show_price: '报价单显示价格',
    download_dialog_title: "下载文件",
    download_dialog_tip: "你要下载哪种格式的文件？",
    customer_dialog_title: "请选择客户",
    edit_product_dialog_title: "编辑产品",
    add_dialog_title: "添加产品",
    search_customer_placeholder: '请输入客户名称',
  },
  tech_info: {
    file_type: '文件类型',
    software: '软件',
    hardware: '硬件',
    structure: '结构',
    pack: '包装',
    label: '标签',
    BOM: 'BOM',
    specification: '说明书',
    tool: '工具',
    date: '日期',
    created_date: '创建日期',
    File_version: '版本号',
    File_version_placeholder: '请输入版本号',
    audit_status: '审核状态',
    audit: '待审核',
    completed_audit: '已完成审核',
    auditor: '审核人',
    relevant_file: '关联文件',
    tooltip: '此查看权限申请通过后只有7天时效',
    reason: '申请原因',
    request_view: '查看申请',
    tech_file_tip: '此查看权限申请通过后只有7天时效',
  },
  purchase: {
    user: '创建人',
    company: '公司名称',
    contract_contents: '合同内容',
    amount: '金额',
    head: '负责人',
    contract_NO: '合同编号',
    remark: '备注',
    date: '日期',
    folder_name: '文件夹',
    material_name: '物料名称',
    spec: '规格型号',
    unit_measure: '计量单位',
    apply_remark: '备注',
    apply_status: '申请状态',
    material_type: '物料类型',
    material_number: '物料编号',
    approval_status: '审批状态',
    approval_remark: '审批备注',
    old_number: '旧物料号',
    agree: '同意',
    refuse: '拒绝',
    no_approval: '未审批',
    undone: '未完成',
    done: '已完成',
    cancel: '取消',
    approval: '审批',
    error: '审批已结束',
    code_rule: '编码格式',
    proposer: '申请人',
    approver: '审批人',
    origin: '产地',
    brand_model: '品牌型号',
    picture: '图片',
    adduser: '申请人',
    check_type: '商品类型',
    check_type_1: '普通',
    check_type_2: '套装',
    check_type_3: '服务',
    isserial: '序列号管理',
    isbatch: '批次管理',
    is_sale: '可销售',
    is_purchase: '可采购',
    is_subpart: '可为子件',
    is_assembly: '可为组件',
    is_out_source: '可委外',
    is_self_restraint: '可自制',
    is_kf_period: '保质期管理',
    kf_period_type: '保质期单位',
    kf_period: '保质期',
    alarm_day: '预警天数',
    kf_unit_1: '天',
    kf_unit_2: '月',
    kf_unit_3: '年',
  },
  menus: {
    Dashboard: 'Dashboard',
    board: '板块',
    print: '打印',
    file: '文件',
    file_classification: '文件分类',
    PLM: 'PLM',
    SOP: 'SOP',
    compare_price: '比价单',
    product: '产品管理',
    product_m: '产品',
    model: '产品详情',
    model_category: '产品分类',
    price_management: '价格管理',
    quote: '报价单',
    normal: '标准报价单',
    special: '特殊报价单',
    okr: '目标关键结果',
    order: '订单管理',
    mps: '生产计划',
    mrp: '物料报缺',
    purchasing_requisition: '采购申请',
    inspection: '质量',
    quality: '质量文件',
    iqc: '来料检验',
    process_defect: '过程不良品',
    rma: '售后',
    rma_foreign: '国外售后',
    rma_domestic: '国内售后',
    contacts: '人事管理',
    department: '部门管理',
    user: '用户',
    techinfo: '技术信息',
    techinfo2: '技术信息2',
    technical_files: '技术文件',
    purchase: '采购管理',
    amn: '物料号管理',
    purchase_contract: '采购单',
    admin: '管理员',
    users: '用户管理',
    userInfo: '用户信息',
    role: '角色管理',
    perm: '权限管理',
    test: '仪器检测',
    add_test: '添加检测数据',
    test_detail: '查看检测信息',
    test_edit: '编辑检测信息',
    test_copy: '复制检测信息',
    oqc_template: '出厂检验报告模板',
    feedback: '质量反馈',
    record: '维修记录',
    maintenance: '维修记录',
    crm: 'CRM',
    customer: '客户管理',
    operate: '运营',
    product_process: '生产过程',
    instruction: '说明书',
    email: '邮件通知',
    contact: '联系人管理',
    approvalFlow: '审批流程',
    supplier: '供应商',
    contract: '合同',
    craft: '工艺',
    craft_feedback: '工艺反馈',
    SN_scrapped: '报废SN',
    documents: '文档资料',
    process_test_documents: '工艺测试文件',
    log: '日志',
    file_log: '文件日志',
    tech_info_file_log: '技术信息日志',
    material_apply_log: '物料号日志',
    instrument_test_file_download_log: '仪器检测文件日志',
    material_num: '物料号',
    apply_mn: '申请物料号',
    notification: '通知',
    material_classification: '物料分类',
    SOP_file: 'SOP文件',
    pm: '项目管理',
    unit: '单位',
    mn_brand: '物料品牌',
    /*仓库管理*/
    warehouse_management: '仓库管理',
    administrative_warehouse: '行政仓库',
  },
  purchasing_requisition: {
    purchase_requisition: '采购申请单',
    basic_information: '基本信息',
    document_number: '单据编号',
    applicant: '申请人',
    application_department: '申请部门',
    estimated_total_amount: '预计总金额 ',
    approver: '审批人',
    notes: '备注',
    application_date: '申请日期',
    purchaser: '采购员',
    audit: '审核状态',
    RD_projects: '研发项目',
    product: '产品',
    detailed_list: '明细列表',
    add: '新增',
    edit: '修改',
    delete: '删除',
    serial_number: '序号',
    material_number: '物料号',
    material_name: '物料名称',
    specification_and_model: '规格型号',
    unit: '单位',
    number: '数量',
    estimated_unit_price: '预计单价',
    estimated_total_price: '预计总价',
    remark: '备注',
    related_accessories: '相关附件',
    review_failed: '审批拒绝',
    audited: '审批通过',
    first_approval: '一级审批中',
    in_approval: '比价中',
    second_approval: '二级审批中',
    pending_approval: '待审批',
    purPriceComparison: '采购比价表',
    procurementDept: '采购部门',
    purchasedept: '采购部',
    purnumber: '编号',
    supplier: '供应商',
    productName: '品名',
    purQuantity: '采购数量',
    planDemand: '需求交期',
    deliverydate: '交货日期',
    deliverymethod: '送货方式',
    freight: '运费',
    quote: '报价',
    priceintax: '含税总价',
    bill: '票据(增票/普票/无票)',
    settlement: '结算方式',
    logistics: '物流',
    expressdelivery: '快递',
    selfpickup: '自提',
    universalticket: '普票',
    additionalvotes: '增票',
    noticket: '无票',
    suggested_supplier: '建议供应商',
    partial: '部分采购',
    purchased: '已采购',
    buy_status_ms: '是否确认已采购？',
  },
  purchase_review: {
    RD_projects: '研发项目',
    estimated_total_amount: '预计总金额',
    actual_total_amount: '实际总金额',
    remark: '备注：',
    serial_number: '序号',
    material_number: '料号',
    material_name: '物料名称',
    specification_and_model: '规格型号',
    unit: '单位',
    number: '数量',
    estimated_unit_price: '预计单价',
    estimated_total_price: '预计总价',
    actual_unit_price: '实际单价',
    actual_total_price: '实际总价',
    // review_failed_comments:'审核未通过意见',
    review_failed_comments: '审核意见',
    finding_of_audit: '审核结果',
    goback: '返回',
    process: '审核',
    firstResults: '一级审批结果',
    purchaseResults: '购买结果',
    supplier: '供应商名称',
    unitprice: '单价',
    amount: '金额',
    comparator: '比价人',
    approvaltime: '审批时间',
    approver: '审批人',
    approvalres: '审批结果',
    appopinions: '审批意见',
    approvalInfo: '审批信息',
    compareprices: '比价',
    Pricec_details: '比价明细',
    Compare_or_not: '是否比价',
  },
  purchase_btn: {
    view: '查看',
    edit: '编辑',
    del: '删除',
    export: '导出',
    PM: '项目管理'
  },
  approvalFlow: {
    name: '名称',
    type: '标识',
    content: '内容',
    Approver: '审批人',
    product_approval: 'PN审批',
    mrp_approval: '物料报缺审批',
    tech_audit: '技术资料审批',
    amn_approval: '物料号审批',
    approvalFlowInfo: '审批流程信息',
  },
  dashbord: {
    unpurchase_0: '仪表-未采购',
    intransit_0: '仪表-在途',
  },
  techinfo: {
    user: '用户'
  },
  IQC: {
    time: '检验时间',
    user: '检验人员',
    quantity: '检验数量',
    ratio: '检验比例',
    batch: '批次号',
    standard: '检验标准',
    result: '检验结果',
    res_0: '合格',
    res_1: '入库',
    pass: '不合格',
    checkout: '检验',
    status_0: '待检验',
    status_1: '待入库',
    // status_2: "已入库",
    status_2: '待退货',
    status_3: '待报废',
    status_4: '已入库',
    status_5: '已检验',
    mes_6: '来料检验',
    batch_quality: '批次数量',
    pass_quality: '合格数量',
    pass_ratio: '合格率',
    mes: '数据输入不完全',
    result_1: '退货',
    result_2: '报废',
    result_3: '让步接收',
    followup: '后续处理',
    result_4: '供方返工处理',
    result_5: '本厂返工处理',
    type_0: '主动元件',
    type_1: '光电元件',
    type_2: '电气元件',
    type_3: '流体部件',
    type_4: '线缆线束',
    type_5: '结构件',
    type_6: '包材类',
    type_7: '印刷类',
    type_8: '电路板',
    type_9: '胶水',
    type_10: '其他',
    type_11: '螺丝&O圈',
    standard_0: '暂无',
    standard_4: 'QP-IQC-01-2020',
    standard_5: 'QP-IQC-11-2021',
    standard_6: 'QP-IQC-02-2022',
    standard_7: 'QP-IQC-03-2022',
    standard_11: 'QP-IQC-11-2022',
  },
  perm: {
    name: '名称',
    code: '标识',
    intro: '描述',
    open: '开启',
    enable: '启用',
    num: '序号',
    id: '编号',
    add_perm: '添加权限',
    edit_perm: '编辑权限',
    roles: '角色',
    assign_roles: '分配角色',
    assign_perm: '分配权限',
    role_management: '角色管理',
    add_role: '新增角色',
    edit_role: '编辑角色',
    role_name: '角色名称',
    role_intro: '角色描述',
    require_name_tip: '权限名称不能为空',
    require_code_tip: '权限标识不能为空',
    info: '角色信息',
    perm: '权限',
  },
  test: {
    add_template: '添加模板',
    add_report_template: '出厂检测模板',
    create_report: '生成报告',
    template_type: '模板类型',
    template_file: '模板文件',
    cell_PN: 'PN值所在单元格',
    cell_SN: 'SN值所在单元格',
    cell_instrument_type: '仪器类型值所在单元格',
    cell_report_code: '报告编码值所在单元格',
    cell_test_date: '检测日期值所在单元格',
    cell_software: '程序版本值所在单元格',
    cell_hardware: '硬件版本值所在单元格',
    cell_mac: '蓝牙Mac值所在单元格',
    cell_modbus: 'modbus值所在单元格',
    cell_tester: '检测人员值所在单元格',
    cell_auditor: '审核人员值所在单元格',
    portable: '便携式仪器',
    portable2: '便携式仪器(二)',
    handheld: '手持式仪器',
    handheld2: '手持式仪器(二)',
    online: '在线式仪器',
    in_line: '在线式(光学)仪器',
    electrode_box: '电极盒测试',
    handheld_instrument: '手持式比色仪器',
    handheld_colorimetric: '手持式比色回读仪器',
    create_excel: '生成Excel',
    instrument_type: '产品型号',
    report_code: '报告编码',
    test_date: '检测日期',
    software: '软件版本',
    hardware: '硬件版本',
    mac: '蓝牙Mac',
    tester: '检测人员',
    auditor: '责任人',
    parameter: '参数文件',
    qualified: '合格',
    unqualified: '不合格',
    qualified_or_not: '是否合格',
    retest: '在测试',
    repairs: '在维修',
    end: '结束',
    concession: '让步出货',
    device: '设备',
    start: '开始时间',
    end_: '结束时间',
    item: '项目名称',
    cn: '中文',
    en: '英文',
    template: '无此语言模板',
    adaptor: '适配器',
  },
  process_defect: {
    id: '序号',
    date: '日期',
    material_code: '物料号',
    material_name: '物料名称',
    bad_description: '不良说明(文字)',
    bad_description1: '不良说明(图片)',
    test: '检验',
    followup: '后续处理',
    required_return: '需退量',
    actual_return: '实退量',
    classification: '问题归类',
    q_feedback1: '质量反馈(文字)',
    q_feedback2: '质量反馈(图片)',
    feedback: '反馈人',
    analysis: '质量分析',
    type_0: "技术问题",
    type_1: "制程不良",
    type_2: "来料不良",
    type_3: "工艺变更",
    type_4: "其他",
  },
  record: {
    adduser: '送修用户',
    feedback: '问题反馈',
    analysis: '故障分析',
    repair: '故障维修',
    type: '故障分类',
    c_time: '维修完成时间',
    type_0: '技术问题',
    type_1: '制程不良',
    type_2: '来料不良',
    type_3: '检测不良',
    type_4: '其他',
    edituser: '责任人',
    result: '检测结果',
    status_0: '待修',
    status_1: '待测',
    status_2: '完结',
    status_3: '未修复',
    test_remark: '检测备注',
    after_sale: '售后维修',
  },
  release: {
    code: '编码',
    number: '序号',
    release_type: '释放类型',
    release_info: '释放信息',
    release_info_placeholder: '请输入释放信息',
    upload_file_department: '上传文件部门',
    pn: 'PN',
    model: '产品型号',
    uploadFiles: '上传文件',
    givedept: '发放部门',
    basicDoc: '基础文件',
    bom: '结构-BOM',
    sop_fit: '作业指导书-装配',
    sop_test: '作业指导书-检测',
    packageFile: '包装文件',
    MecDrawing2D: '机械图纸2D',
    MecDrawing3D: '机械图纸3D',
    electric_base: '电气-基础文件',
    electric_SCH: '电气-原理图',
    electric_wire_map: '电气-接线图',
    instructions: '说明书',
    RDdata: '研发资料',
    others: '其它',
    selectDept: '请选择部门',
    free: '释放',
    release_memorandum: '备忘录',
    freedet: '释放详情',
    release_details: '释放详情',
    add_release: '添加释放',
    edit_release: '编辑释放',
    sendNotice: '下放通知',
    releaseTime: '释放时间',
    changeTime: '更改时间',
    feedback_status: '反馈状态',
    file_version: '文件版本号',
    file_version_placeholder: '请输入文件版本号',
    version: '版本号',
    version_placeholder: '请输入版本号',
    software_version: '软件版本号',
    software_version_placeholder: '请输入软件版本号',
    techinfo: '技术更改信息',
    suggest: '建议执行操作',
    operation: '运营执行方案',
    pro_change: '硬件变更',
    prodet: '更改详情',
    proadd: '更改添加详情',
    change_detail: '更改详情',
    add_new_software_change: '添加软件变更',
    proedit: '更改编辑详情',
    proapprove: '待审批',
    review_tip: '请前往代码仓库审核代码',
    waiting: '待编辑',
    tech_audited: '技术已审核',
    tech_edit: '技术已编辑',
    operate_edit: '运营已编辑',
    stock: '库存数量',
    wip: '在制品数量',
    uploadfile: '技术文件已上传',
    filedep: '文件部门',
    no_feedback: '待反馈',
    not_edited: '待编辑',
    choose_status: '请选择状态',
    add_pn_dialog_title: "添加PN",
    add_file_dialog_title: "添加文件",
    edit_file_dialog_title: "修改文件",
    software_change_upgrade_both: "用户端及库存升级",
    software_change_upgrade_client: "用户端升级但库存不升级",
    software_change_upgrade_inventory: "用户端不升级但库存升级",
    software_change_not_upgrade_both: "用户端及库存均不升级",
    please_select_a_level: "请选择等级",
    please_select_a_status: "请选择状态",
    changed_info: '变更信息',
    file_info: '文件信息',
    search_pn_placeholder: '请输入PN以搜索...',
    feedback_timeout: '反馈超时时间',
    approval_info: '审批信息',
    release_approval: '释放审批',
    wait_for_file_upload: '等待文件上传',
    wait_for_technical_review: '等待技术审核',
    technical_approval: '技术审核通过',
    final_approval: '最终审核通过',
    audit_reject: '审核拒绝',
    upload_file_confirm_ask_title: '请确认需要您上传的文件是否全部上传完毕？',
    upload_finished: '所有文件已上传完毕',
    upload_not_finished: '还有文件未上传，稍后继续上传',
    files_to_reject: '拒绝文件',
    release_status: '释放状态',
    release_file_upload_status: '文件上传情况',
    error_file_exist: '同名文件已存在，请选择其他文件',
    pass_ratio: '试制合格率',
    pass_ratio_placeholder: '请输入试制合格率',
    delay_deadline_placeholder: '选择滞后期限',
    trial_production_quantity: '试制总数量',
    trial_production_quantity_placeholder: '请输入试制总数量',
    qualified_quantity_of_trial_production: '试制合格数量',
    qualified_quantity_of_trial_production_placeholder: '请输入试制合格数量',
    work_hours: '总工时',
    work_hours_placeholder: '请输入总工时',
  },
  notice: {
    notification: '消息通知',
    notify: '消息通知',
    ordinaryNotice: '普通通知',
    releaseNotice: '释放通知',
    changeNotice: '更改通知',
    softchangeNotice: '软件更改通知',
    feedbacktz: '反馈通知',
    feedbacktime: '反馈时间',
    feedback: '反馈',
    subfeedback: '提交反馈',
    title: '标题',
    ifRead: '是否已读',
    sendTime: '发送时间',
    type: '类型',
    details: '详情',
    read: '已读',
    unread: '未读',
    content: '内容',
    insite: '站内通知',
    newtime: '新建时间',
    receivedep: '接收部门',
    receiveuser: '接收人 ',
    insiteDet: '站内通知详情',
    electronic: '电子电器',
    structural: '结构包装',
    engineeing: '工艺工程',
    documents: '文档资料',
    feedback_type: '反馈类型',
    chemical: '化学工程',
    product_change_details: '产品变更详细信息',
  },
  // 工艺
  craft: {
    feedback: '反馈',
    c_feedback1: '工艺反馈(文字)',
    c_feedback2: '工艺反馈(图片)',
    analysis: '工艺分析',
    feedback_status: '反馈状态',
    feedback_detail: '反馈详情',
    assemble_sop: '装配SOP',
    pack_sop: '包装SOP',
    test_sop: '检测SOP',
    update_content: '更新内容',
  },
  //软件变更
  soft_change: {
    pro_soft_change: '软件变更',
    pro_soft_add: '软件更改添加',
    change_software: '变更软件',
    change_level: '变更等级',
    download_link: '下载链接',
    change_content: '变更内容',
    please_select_pn: '请填写PN号',
    please_fill_change_software: '请填写变更软件',
    please_fill_download_link: '请填写下载链接',
    please_fill_change_level: '请填写变更等级',
    please_fill_change_content: '请填写变更内容',
    operation_failed: '操作失败！',
    please_fill_tech_info: '请填写技术更改信息'

  },
  // 物料号
  material: {
    category_type_subclass: '选择类别-类型-细类',
    edit_classification: '编辑分类',
    view_classification: '查看分类',
    add_classification: '添加分类',
    category_name_aonnot_none: '分类名称不能为空',
    category_code_aonnot_none: '分类标识不能为空',
    enter_two_code: '请输入两位编码',
    enter_key_filter: '输入关键字进行过滤',
    code: '编码',
    parent_level: '父级',
    batch_approval: '批量审批',
    attachment: '附件',
  },
  cable_dialog: {
    "sensor_side_explosion": "Sensor side Explosion-proof",
    "length": "Length",
    "unit": "Unit",
    "the_other_side_PIN": "The other side PIN",
    "the_other_side_adaptor": "The other side adaptor",
    "the_other_side_explosion": "The other side Explosion-proof",
    "sensor_side_PIN": "Sensor side PIN",
    "sensor_side_adaptor": "Sensor side adaptor",
  },
  quality: {
    add_quanitiy_dialog_title: '添加质量文件',
  },
  pm: {
    name: '项目名称',
    intro: '项目介绍',
    status: '状态',
    start: '开始时间',
    end: '结束时间',
    remark: '备注',
    new_pm_title: "添加研发项目",
    edit_pm_title: "编辑研发项目",
    add_pm_success: "添加研发项目成功",
    add_pm_error: "添加研发项目失败",
    edit_pm_error: "编辑研发项目失败",
    edit_pm_success: "编辑研发项目成功",
    active: '有效',
    inactive: '无效',
    schematic: '示意图',
    upload: '点击上传',
    hint: 'jpg/png不得超过5M'
  },
  number: {
    time: '日期',
    code: '商品编号',
    name: '商品名称',
    spec: '型号规格',
    status: '状态',
    remark: '备注',
    release: '释放',
    change: '变更',
    mn_search: '请输入物料号搜索'
  },
  unit: {
    name: '单位名称',
    intro: '描述',
    precision: '精度'
  },
  mn_brand: {
    name: '品牌名称',
    number: '品牌编码',
    remark: '备注'
  },
  warehouse: {
    administrative_warehouse_material_details: '物料信息',
    material_name: '物料名称',
    material_code: '物料号',
    material_spec: '物料规格',
    material_source: '来源',
    material_source_placeholder: '请选择来源',
    add: '入库',
    code: '编号',
    code_placeholder: '请选择编号',
    apply: '申领',
    apply_dialog_title: '物料申领',
    apply_type: '申领类型',
    my_application: '我的申请',
    apply_type_placeholder: '请选择申请类型',
    wait_for_review: '待审批',
    approved: '审批通过',
    approval_rejected: '审批拒绝',
    wait_for_return: '待归还',
    return_finished: '已归还',
    borrow_dialog_title: '物料外借',
    details: '详情',
    select_material: '选择物料',
    approver: '审批人',
    approver_select_placeholder: '请选择审批人',
    remark: '备注',
    add_material: '添加',
    delete_material: '删除',
    add_material_select_material: '选择物料',
    add_material_select_material_placeholder: '输入物料号/物料名称以搜索',
    add_material_dialog_title: '物料入库',
    add_material_unit: '单位',
    add_material_remark: '备注',
    add_material_unit_price: '单价',
    add_material_total_price: '总价',
    add_material_number: '入库数量',
    add_material_name_placeholder: '请输入物料名称',
    add_material_select_placeholder: '请选择物料',
    add_material_spec_placeholder: '请输入物料规格',
    add_material_unit_placeholder: '请输入物料单位',
    add_material_unit_price_placeholder: '请输入单价',
    add_material_remark_placeholder: '请输入备注',
    return_material_dialog_title: '物料归还',
    material_basic_information: '物料基本信息',
    basic_information: '基本信息',
    material_add_history: '物料入库记录',
    material_apply_history: '物料申请记录',
    apply_history: '申请记录',
    material_approval_history: '物料审批记录',
    total_pending_approval: '待审批数量',
    total_received: '已领用数量',
    total_borrowed: '已借用数量',
    total_inventory: '总库存',
    available_quantity: '可用库存',
    quantity_of_applications: '申请数量',
    total_quantity_of_applications: '总申请数量',
    borrow: '外借',
    receive: '领用',
    return: '归还',
    return_success: '归还成功',
    applicant: '申请人',
    apply_time: '申请时间',
    approval_time: '审批时间',
    approval_of_application: '审批',
    agree: '同意',
    reject: '拒绝',
    approval_result: '审批结果',
    approval_result_placeholder: '请选择审批结果',
    approval_opinion: '审批意见',
    approval_opinion_placeholder: '请输入审批意见',
    approval_type: '审批类型',
    approval_status: '审批状态',
    operation_log: '操作日志',
    depositor: '入库人',
    depositor_time: '入库时间',
    sum_price: '合计',
    fixed_supplier: '固定供应商',
    other_supplier: '其他供应商',
    taobao_jingdong: '淘宝/京东',
    material_type: '物料类型',
    administrative_supplies: '行政用品',
    safety_supplies: '安全用品',
    return_date: '归还日期',
    return_date_placeholder: '请选择归还日期',
    return_date_error: '归还日期必须晚于今日',
    // application_pending_approval: '申领待审批',
    // apply_approval_pass: '申领审批通过',
  }
}

export default zh
