import {createI18n} from 'vue-i18n'
import zh from '@/lang/zh_CH'
import en from '@/lang/en'
import enLocale from 'element-plus/dist/locale/en.mjs' // lang i18n
import zhLocale from 'element-plus/dist/locale/zh-cn.mjs' // lang i18n

const messages = {
  en: {
    message: 'hello',
    ...enLocale,
    ...en,
  },
  zh: {
    message: '你好',
    ...zhLocale,
    ...zh,
  }
}

const i18n = createI18n({
  legacy: false,
  locale: localStorage.getItem('local') || 'zh',
  messages
})

export default i18n
