import * as Vue from 'vue'

import 'normalize.css/normalize.css' // A modern alternative to CSS resets

import ElementPlus from 'element-plus'
// import 'element-plus/theme-chalk/index.css'
import 'element-plus/dist/index.css'
import enLocale from 'element-plus/dist/locale/en.mjs' // lang i18n
// import zhLocale from 'element-plus/dist/locale/zh-cn.mjs' // lang i18n
// import ElementLocale from 'element-plus/lib/locale'

import '@/styles/index.scss' // global css
import '../static/Ueditor/ueditor.config.js'
import '../static/Ueditor/ueditor.all.min.js'
import '../static/Ueditor/lang/zh-cn/zh-cn.js'
import '../static/Ueditor/themes/default/css/ueditor.css'
import App from './App'
import store from './store'
import router from './router'
import CheckPermission from '@/mixin/checkPermission'
import preventClick from '@/utils/controlClickState'
// import VueUeditorWrap from 'vue-ueditor-wrap'

import installIcon from '@/icons' // icon
import '@/permission' // permission control
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online! ! !

 import { mockXHR } from '../mock'
 if (process.env.NODE_ENV === 'production') {
 mockXHR()
 }*/
import './utils/request'
import Components from '@/components'
import Table from "@pureadmin/table";

window.$vueApp = Vue.createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  window.$vueApp.component(key, component)
}
installIcon(window.$vueApp);
// set ElementPlus lang to EN
window.$vueApp.use(ElementPlus, {enLocale})
window.$vueApp.use(Components)
window.$vueApp.use(preventClick)
window.$vueApp.use(Table)
//全局混入检查对象
window.$vueApp.mixin(CheckPermission) //表示所有的组件都拥有了一个检查的方法

import Print from 'vue3-print-nb'

window.$vueApp.use(Print)
// import {createI18n} from 'vue-i18n'

import i18n from './utils/i18n'

var type = navigator.appName
if (type == 'Netscape') {
  var lang = navigator.language //获取浏览器配置语言，支持非IE浏览器
} else {
  var lang = navigator.userLanguage //获取浏览器配置语言，支持IE5+ == navigator.systemLanguage
}
var lang = lang.substr(0, 2) //获取浏览器配置语言前两位
if (lang == 'zh') {
  lang = lang
  localStorage.setItem('lang', lang)
} else if (lang == 'en') {
  lang = lang
  localStorage.setItem('lang', lang)
} else {
  //其他语言编码时打开以下链接
  lang = 'en'
  localStorage.setItem('lang', lang)
}

// import zh from '../src/lang/zh_CH'
// import en from '../src/lang/en'

// const i18n = createI18n({
//   legacy: false,
//   locale: localStorage.getItem('local') || 'zh',
//   messages: {
//     en: {
//       message: 'hello',
//       ...enLocale,
//       ...en,
//     },
//     zh: {
//       message: '你好',
//       ...zhLocale,
//       ...zh,
//     },
//   },
// })
// ElementLocale.i18n((key, value) => i18n.t(key, value))

window.$vueApp.use(i18n)

window.$vueApp.config.globalProperties.routerAppend = (path, pathToAppend) => {
  return path + (path.endsWith('/') ? '' : '/') + pathToAppend
}
window.$vueApp.use(store).use(router).mount('#app')

// window.$vueApp.directive('preventClick', {
//   mounted(el, binding) {
//     el.addEventListener('click', () => {
//       if (!el.disabled) {
//         el.disabled = true
//         // ElementUI.Message({
//         //   message: '请求已发送，10S内连续点击无效',
//         //   type: 'error'
//         // })
//         setTimeout(() => {
//           el.disabled = false
//         }, binding.value || 1000 * 10)
//       }
//     })
//   },
// })
const debounce = (fn, delay) => {
  let timer
  return (...args) => {
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(() => {
      fn(...args)
    }, delay)
  }
}

const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
  constructor(callback) {
    callback = debounce(callback, 200);
    super(callback);
  }
}
