import * as Vue from 'vue'
import Layout from '@/layout'

export default {
  path: '/rma',
  component: Layout,
  name: 'rma',
  // redirect: '/rma/list',
  alwaysShow: true,
  meta: {title: 'menus.rma', icon: 'tools'},
  children: [
    {
      path: 'list',
      name: 'rmaservice',
      component: Vue.defineAsyncComponent(() => import('@/views/RMA/rmas')),
      meta: {title: 'menus.rma_foreign', icon: 'wrench-adjustable-circle'},
    },
    {
      path: 'rma_domestic_after_sales',
      name: 'rma_domestic_after_sales',
      component: Vue.defineAsyncComponent(() => import('@/views/RMA/domestic_after_sales')),
      meta: {title: 'menus.rma_domestic', icon: 'wrench-adjustable-circle-fill'},
    },
    {
      path: 'rmainfo',
      name: 'rmainfo',
      hidden: true,
      subhidden: true,
      component: Vue.defineAsyncComponent(
        () => import('@/views/RMA/rmainfo.vue')
      ),
      meta: {
        title: 'RMAInfo',
        activeMenu: '/rma/list',
        activeTab: 'index',
      },
    },
    {
      path: 'rmasingle',
      name: 'rmasingle',
      hidden: true,
      subhidden: true,
      component: Vue.defineAsyncComponent(
        () => import('@/views/RMA/rmasingle.vue')
      ),
      meta: {
        title: 'Service Report',
        activeMenu: '/rma/list',
        activeTab: 'index',
      },
    },
    {
      path: 'rmasingleList',
      name: 'rmasingleList',
      hidden: true,
      subhidden: true,
      component: Vue.defineAsyncComponent(
        () => import('@/views/RMA/rmasingleList.vue')
      ),
      meta: {
        title: 'rmasingleList',
        activeMenu: '/rma/list',
        activeTab: 'index',
      },
    },
    // {
    //   path: 'list',
    //   name: 'rmaservice',
    //   alwaysShow: true,
    //   component: () => import('@/views/RMA/list'),
    //   meta: { title: 'menus.rma', icon: 'shouhouwuyou' },
    // },
    // {
    //   path: 'info/:id(\\d+)',
    //   name: 'rmainfo',
    //   hidden: true,
    //   component: () => import('@/views/RMA/detail'),
    //   meta: { title: 'Information', noCache: true, activeMenu: '/rma/list' }
    // }
  ],
}
