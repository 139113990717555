import * as Vue from 'vue'
import * as VueRouter from 'vue-router'

/* Layout */
import Layout from '@/layout'
import modelRouter from './modules/model'
import okrRouter from './modules/okr'
// import orderRouter from './modules/order'
// import mpsRouter from './modules/mps'
// import mrpRouter from './modules/mrp'
import qualityRouter from './modules/quality'
import rmaRouter from './modules/rma'
import warehouseRouter from './modules/warehouse'
// import contactsRouter from './modules/contacts'
import techinfoRouter from './modules/techinfo'
import purchaseRouter from './modules/purchase'
import adminRouter from './modules/admin'
// import priceRouter from './modules/model_price'
import quoteRouter from './modules/quote'
import amnRouter from './modules/amn'
import crmRouter from './modules/crm'
import operateRouter from './modules/operate'
import craftRouter from './modules/craft'
import plmRouter from './modules/plm'
import filesRouter from './modules/files'
import documentsRouter from './modules/documents'
import logsRouter from './modules/logs'
// import testRouter from './modules/test'

export const constantRoutes = [
  {
    path: '/login',
    component: Vue.defineAsyncComponent(() => import('@/views/login/index')),
    hidden: true,
  },
  {
    path: '/user',
    component: Layout,
    redirect: '/dashboard',
    children: [
      {
        path: 'passwd',
        name: 'userpassword',
        component: Vue.defineAsyncComponent(
          () => import('@/views/login/passwd')
        ),
      },
    ],

    hidden: true,
  },
  {
    path: '/404',
    component: Vue.defineAsyncComponent(() => import('@/views/error-page/404')),
    hidden: true,
  },
  {
    path: '/dashboard',
    component: Layout,
    redirect: '/dashboard',
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: Vue.defineAsyncComponent(
          () => import('@/views/dashboard/index')
        ),
        meta: {title: 'menus.Dashboard', icon: 'dashboard', affix: true},
      },
    ],
  },
  {
    path: '/',
    component: Layout,
    redirect: '/dashboard/dashboard',
    children: [
      {
        path: 'notice/message',
        name: 'notice_messages',
        component: Vue.defineAsyncComponent(
          () => import('@/views/notice/message')
        ),
      },
    ],
    // hidden: true
  },
  {
    path: '/noticeInfo',
    component: Layout,
    redirect: '/dashboard',
    children: [
      {
        path: 'detailm',
        name: 'detailm',
        component: Vue.defineAsyncComponent(
          () => import('@/views/notice/detailm')
        ),
      },
    ],
    // hidden: true
  },
]

export const asyncRoutes = [
  modelRouter,
  plmRouter,
  filesRouter,
  // priceRouter,
  // quoteRouter,
  okrRouter,
  operateRouter,
  // orderRouter,
  // mpsRouter,
  // mrpRouter,
  qualityRouter,
  craftRouter,
  crmRouter,
  documentsRouter,
  rmaRouter,
  // contactsRouter,
  // techinfoRouter,
  amnRouter,
  // testRouter,
  purchaseRouter,
  logsRouter,
  warehouseRouter,
  adminRouter,
]
const createRouter = () =>
  VueRouter.createRouter({
    history: VueRouter.createWebHistory(),
    routes: [...constantRoutes, ...asyncRoutes], // mode: 'history', // require service support
    scrollBehavior: () => ({
      top: 0,
    }),
    // scrollBehavior: (to, from, savedPosition) => {
    //   if (savedPosition) {
    //     return savedPosition
    //   } else {
    //     return {top: 0}
    //   }
    // },
  })

const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router
