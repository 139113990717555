import * as Vue from 'vue'
import Layout from '@/layout'

export default {
    path: '/materials',
    name: 'materials',
    component: Layout,
    alwaysShow: true,
    meta: {title: 'menus.crm', icon: 'shouhouwuyou'},
    children: [
        {
            path: 'company',
            name: 'materials_company',
            component: Vue.defineAsyncComponent(
                () => import('@/views/customer/index.vue')
            ),
            meta: {title: 'menus.customer', icon: 'customer'},
        },
        {
            path: 'companyinfo',
            name: 'materials_companyinfo',
            hidden: true,
            subhidden: true,
            component: Vue.defineAsyncComponent(
                () => import('@/views/customer/companyInfo.vue')
            ),
            meta: {
                title: '公司管理详情',
                activeMenu: '/materials',
                activeTab: 'index',
            },
        },
        {
            path: 'contact',
            name: 'materials_contact',
            component: Vue.defineAsyncComponent(
                () => import('@/views/customer/contacts.vue')
            ),
            meta: {title: 'menus.contact', icon: 'customer-2'},
        },
        {
            path: 'price',
            name: 'price',
            component: Vue.defineAsyncComponent(
                () => import('@/views/price/product_price.vue')
            ),
            meta: {title: 'menus.price_management', icon: 'price'},
        },
        {
            path: 'quotation',
            name: 'quote',
            meta: {title: 'menus.quote', icon: 'money-check'},
            children: [
                {
                    path: 'list',
                    name: 'priceNormalquote',
                    component: Vue.defineAsyncComponent(
                        () => import('@/views/price/product_normal_quote.vue')
                    ),
                    meta: {title: 'menus.normal', icon: 'quotation-text', affix: true},
                },
                {
                    path: 'specials',
                    name: 'priceSpecialquote',
                    component: Vue.defineAsyncComponent(
                        () => import('@/views/price/product_special_quote.vue')
                    ),
                    meta: {title: 'menus.special', icon: 'quotation-text-special', affix: true},
                },
                {
                    path: 'regular/add',
                    name: 'normalquote',
                    hidden: true,
                    component: Vue.defineAsyncComponent(
                        () => import('@/views/price/normal_quote.vue')
                    ),
                    meta: {
                        title: 'Normal Quote',
                        noCache: true,
                        activeMenu: '/quotation/list',
                    },
                },
                {
                    path: 'regular/:id(\\d+)/',
                    name: 'normalquote_view',
                    hidden: true,
                    component: Vue.defineAsyncComponent(
                        () => import('@/views/price/normal_quote_view.vue')
                    ),
                    meta: {
                        title: 'Normal Quote',
                        noCache: true,
                        activeMenu: '/quotation/list',
                    },
                },
                {
                    path: 'regular/:id(\\d+)/edit',
                    name: 'normalquotedetail',
                    hidden: true,
                    component: Vue.defineAsyncComponent(
                        () => import('@/views/price/normal_quote.vue')
                    ),
                    meta: {
                        title: 'Normal Quote',
                        noCache: true,
                        activeMenu: '/quotation/list',
                    },
                },

                {
                    path: 'special',
                    name: 'specialquote',
                    hidden: true,
                    component: Vue.defineAsyncComponent(
                        () => import('@/views/price/special_quote.vue')
                    ),
                    meta: {
                        title: 'Special Quote',
                        noCache: true,
                        activeMenu: '/quotation/specials',
                    },
                },
                {
                    path: 'special/:id(\\d+)',
                    name: 'specialquote_view',
                    hidden: true,
                    component: Vue.defineAsyncComponent(
                        () => import('@/views/price/special_quote_view.vue')
                    ),
                    meta: {
                        title: 'Special Quote',
                        noCache: true,
                        activeMenu: '/quotation/specials',
                    },
                },
                {
                    path: 'special/:id(\\d+)/edit',
                    name: 'specialquotedetail',
                    hidden: true,
                    component: Vue.defineAsyncComponent(
                        () => import('@/views/price/special_quote.vue')
                    ),
                    meta: {
                        title: 'Special Quote',
                        noCache: true,
                        activeMenu: '/quotation/specials',
                    },
                },
            ],
        },
        {
            path: 'contactsinfo',
            name: 'crm_contactsinfo',
            hidden: true,
            subhidden: true,
            component: Vue.defineAsyncComponent(
                () => import('@/views/customer/contactsInfo.vue')
            ),
            meta: {
                title: '联系人管理详情',
                activeMenu: '/crm',
                activeTab: 'index',
            },
        },
        // {
        //   path: 'manual',
        //   name: 'material_manual',
        //   component: () => import('@/views/customer/manual.vue'),
        //   meta: { title: 'menus.instruction', icon: 'tech' }
        // }
    ],
}
